import React,{  useEffect  ,useState,useRef,Suspense } from "react";
import { useLoader, useThree } from "@react-three/fiber";
import { AudioLoader, AudioListener  } from "three";
import { useAudioManager } from './useAudioManager';
import {Html}  from '@react-three/drei';

function SoundCore({id, play,data,...props}){
    const ref = useRef();
    const [listener] = useState(() => new AudioListener());
    const { camera } = useThree();

    const setLoaded = useAudioManager((state) => state.setLoaded);
    const buffer = useLoader(AudioLoader, data[id].path, null, (xhr) => {
        if (xhr.loaded === xhr.total) {
          // const de = document.getElementById('debug-0');
          //  de.innerHTML = "AMBIENT: Buffer ready:";
            setLoaded(id, true);

        }
    });


    const loaded = useAudioManager((state) => state.didLoad[id]);
    const setAudioRef = useAudioManager((state) => state.setAudioRef);

    useEffect(()=> {
        camera.add(listener);
        return () => camera.remove(listener);

    },[listener,camera]);

    useEffect(() => {
        const sound = ref.current;

        if (sound && loaded ) {
            sound.setBuffer(buffer);
            sound.setLoop(true);
            sound.volume = data[id].volume;
            sound.name = id;
            setAudioRef(id,sound);
        }
        return () => {
            if (sound && play) {
                //const de = document.getElementById('debug-0');
                //de.innerHTML += "bye bye bey";
                //de.innerHTML += "THIS IS THE END!";
                try{
                    sound.stop();
                    sound.disconnect();
                }
                catch(err){
                   // de.innerHTML += err.message;
                }
            };
        }
        // eslint-disable-next-line
    },[id,buffer,loaded,data ]);



    return <audio name={id} ref={ref} args={[listener]} {...props} />;
};

function Sound({id, start, data}){
    return(  <Suspense fallback={<Html><h1>Suspense loading audio</h1></Html>}>
             <SoundCore id={id} data={data} start={start}/>
             </Suspense>
);}

export default Sound;
