import { useState, useEffect ,Suspense} from "react";
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import {Html}  from '@react-three/drei';

function Model({ path, objFile, mtlFile,  onSuccess, position, ...props}) {
    const INIT = 0;
    const LOADING = 1;
    const READY = 2;
    const [state, setState ] = useState(INIT);
    const [model, setModel ] = useState(INIT);

    useEffect(()=>{
        if (state === READY || state === LOADING) return;

        setState(LOADING);
        var mtlLoader = new MTLLoader();
        mtlLoader.setPath(path)
        mtlLoader.setMaterialOptions( { invertTrProperty: true } )
        mtlLoader.load(mtlFile, function(materials)
                       {

                           materials.preload();
                           var objLoader = new OBJLoader();
                           objLoader.setPath(path);
                           objLoader.setMaterials(materials);

                           objLoader.load(objFile, function(object)
                                          {
                                              setModel(object);
                                              setState(READY);

                                          }, (xhr) => {
                                              const normalizedLoad = xhr.loaded / xhr.total;

                                              if(normalizedLoad ===1){
                                             //    console.log(normalizedLoad * 100 + '% loaded ' )
                                              }
                                          },
                                          (error) => {
                                              console.log(error)
                                          })
                       });

      
    },[state,path, objFile, mtlFile] );

    return (state === READY) ?
        (
                <Suspense fallback={<Html><h1>Suspense loading model</h1></Html>}>
                <primitive position={position} object={model} scale={3}/>
                </Suspense>
        )
        : <Html><h1>Model reference loading.. </h1></Html>;
}

export default Model;
