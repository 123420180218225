import { EffectComposer, GodRays, DepthOfField, Bloom  } from '@react-three/postprocessing'
import { BlendFunction, KernelSize } from 'postprocessing'
import { useRef, useState, forwardRef, useEffect } from 'react'
import Particles from './ParticlesFast.js'
import {isMobile} from 'react-device-detect';
import {  useThree } from '@react-three/fiber'
import { Sparkles } from './Sparkles.js'


const Sun = forwardRef(function Sun(props, forwardRef) {

  return (
    <mesh ref={forwardRef} position={[-8, 20, 0]} scale={.25}>
      <spotLight shadow-mapSize-width={512} shadow-mapSize-height={512} angle={1.2} distance={20} />
          <
          sphereGeometry args={[1, 36, 36]} />
      <meshBasicMaterial color={'#efcf80'} />
    </mesh>
  )
})

function CustomEffects() {
    const sunRef = useRef();

    let [sun, setSun] = useState(false);
    useEffect(() => {
        setSun(true)
    }, [sunRef])
    
    const [ ,setParticlesRefLight] = useState(null);

    const {camera} = useThree();


    return (
            <>
            <Sun ref={sunRef} />
            <Particles pointCount={isMobile ? 500 : 1000} position={[-5,1 , 10]} />
            <Sparkles callbackRef={setParticlesRefLight} count={50} color="#f5f5f5"/>
            <Sparkles  count={150} position={[0,-2,-15]} color="#aa5700"  scale={1.}/>
            {sun &&
             <EffectComposer>
             <DepthOfField focusDistance={0.05} focalLength={0.15} bokehScale={3} height={580} />
             <GodRays
             sun={sunRef.current}
             blendFunction={BlendFunction.Screen}
             samples={20}
             density={.97}
             decay={1.}
             weight={.5}
             exposure={.5}
             clampMax={1}
             kernelSize={KernelSize.MEDIUM}
             blur={true}
             />
             {(camera.position && camera.position.z>11.75) ?
              <>
              <Bloom luminanceThreshold={.005} luminanceSmoothing={0.1} height={100} />
              </>
              : null}

             </EffectComposer>

            }
        </>
    )
}

export default CustomEffects

/*
  <SelectiveBloom lights={[sunRef]} selection={lightRef} selectionLayer={60} intensity={3.0} luminanceThreshold={0.1} luminanceSmoothing={0.09} />

  <ToneMapping
  blendFunction={BlendFunction.NORMAL}
  adaptive={true}
  resolution={512}
  middleGrey={0.6}
  maxLuminance={2.0}
  averageLuminance={1.0}
  adaptationRate={1.0}
  />


*/
