import React  from "react";
import DisplayInfo from './DisplayInfo.js';
import '../styles/ui.css';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        console.log(error)
        this.setState({
            error: error,
            errorInfo: errorInfo
        })

    }

    render() {

        if (this.state.errorInfo) {

            return (
                    <DisplayInfo  id={"error"} styleClass="about" openText="Error" show={true}>
                    <div className="error-text">
                    <h2>Something went wrong.</h2>
                    <p><a href="mailto:studio@untold.garden">Please contact us. </a></p>
                    <h3 ><a className="or" href="/">start again</a></h3>
                    </div>
                    </DisplayInfo>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary;
