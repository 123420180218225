import Sound from './Sound.js';
import PositionalSound from './PositionalSound.js';
import { isMobile } from 'react-device-detect';
//import { useAudioManager } from './useAudioManager.js';
//import { useFrame } from "@react-three/fiber";

const data = {
    "audiofull":{
        volume:.5,
        path:  '/audio/audiofull.mp3'
    },
    "audio1":{
        volume:.2,
        path:  '/audio/audio1.mp3'
    },
    "audio2":{
        position: [8.,0,-9],
        path:  '/audio/audio2.mp3',
        rotation: [0, 0,0],
        refdistance: 1.,
        cone:{
            outer: 360,
            inner: 180,
            gain:.1
        },
        maxdistance: 3,
        distancemodel: "exponential",
        volume:0.8
    },
    "audio3":{
        position: [3,-2, 7],
        path:  '/audio/audio3.mp3',
        rotation: [0,Math.PI/2,0],
        refdistance:1.,
        cone:{
            outer: 360,
            inner: 90,
            gain:.1
        },
        maxdistance: 3,
        distancemodel: "exponential",
        volume:0.8
    },
    "audio4":{
        position: [5,-.5,-7.5],
        path:  '/audio/audio4.mp3',
        rotation: [2,0,0],
        refdistance:1.,
        cone:{
            outer: 120,
            inner: 90,
            gain:.1
        },
        maxdistance: 3.,
        distancemodel: "exponential",
        volume:0.8
    },
    "audio5":{
        position: [7.,-1,-2],
        path:  '/audio/audio5.mp3',
        rotation: [1.5,0,0],
        refdistance:1.,
        cone:{
            outer: 90,
            inner: 45,
            gain:.1
        },
        maxdistance: 3.,
        distancemodel: "exponential",
        volume:0.8
    }
};


function Audio({start}) {

/* DEBUGGING CODE:
   const didLoadAllAudio = (state) =>{
   const v = Object.values(state.audiosRef).every(Boolean)
   return v;
   };

   const allAudioLoaded =  useAudioManager(didLoadAllAudio);


    const a1 = useAudioManager((state)=>state.audiosRef["audio1"]);
    const a3 = useAudioManager((state)=>state.audiosRef["audio3"]);
    const a4 = useAudioManager((state)=>state.audiosRef["audio4"]);
    const a5 = useAudioManager((state)=>state.audiosRef["audio5"]);
    const afull = useAudioManager((state)=>state.audiosRef["audiofull"]);

    const [audioElements] =isMobile ? [afull] : [a1,a3,a4,a5];

    useFrame(()=>{
        if(allAudioLoaded && start){
            const de = document.getElementById('debug-0');
            de.innerHTML ='';
            for (var i = 0; i < audioElements.length; i++) {
                const audioCtx = audioElements[i].context;
                de.innerHTML = "Audio Element "+ ae.name +": "+  + Math.floor(ae.source.context.currentTime||0) +" ||  " +ae.source.context.state + '<br>';
               if(audioCtx.state === 'suspended') {
                    audioCtx.resume().then(function() {
                        de.textContent += 'Suspend context';
                    });
                }
            }

        }
    })
 */
    return (isMobile ?
            <Sound id={"audiofull"} play={start} data={data}/>
            :
            <>
            <Sound id={"audio1"} play={start} data={data}/>
            <PositionalSound id={"audio3"} play={start} data={data}/>
            <PositionalSound id={"audio4"} play={start} data={data}/>
            <PositionalSound id={"audio5"} play={start} data={data}/>
            </>
           );
}

export default Audio;

