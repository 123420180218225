import React, { useMemo, useRef} from "react"
import {  useFrame } from "@react-three/fiber"
import * as THREE from "three";
import "./materials/particlematerial"


function Particles({ position, pointCount}) {
    const geom = useRef();
    const [positions, sizes, colors] = useMemo(() => {
        const initialCoords = []
        const initialSizes = []
        const initialColors = []

        for (let count = 0; count < pointCount; count++) {
            const x = 20 * Math.random() - 10;
            const y = 20 * Math.random() - 10+10;
            const z = 20 * Math.random() - 10;
            initialCoords.push(x,y,z)
            initialColors.push(0.,0.2,0.9)
            initialSizes.push(Math.random() < 0.3 ? 1 : 3)

        }


        const coords = new Float32Array(initialCoords)
        const sizes = new Float32Array(initialSizes)
        const colors = new Float32Array(initialColors)
        return [coords, sizes,colors]
    }, [pointCount])

      useFrame((state) => {
     geom.current.material.uniforms.time.value = state.clock.getElapsedTime();
      geom.current.geometry.verticesNeedUpdate = true
      })


    return (
            <points ref={geom} position={position}>
            <bufferGeometry attach="geometry">
            <bufferAttribute
        attach="attributes-position"
        count={positions.length / 3}
        array={positions}
        itemSize={3}
        usage={THREE.DynamicDrawUsage}
            />
            <bufferAttribute
        attach="attributes-color"
        count={colors.length / 3}
        array={colors}
        itemSize={3}
        usage={THREE.DynamicDrawUsage}
            />
            />
            <bufferAttribute
        attach="attributes-size"
        count={sizes.length / 3}
        array={sizes}
        itemSize={1}
        usage={THREE.DynamicDrawUsage}
            />
            </bufferGeometry>
            <particleMaterial attach="material" vertexColors vertexSizes sizeAttenuation={false} />
            </points>

    )
}

export default Particles;
