import { useMemo } from "react";
//import {useRef,useEffect} from 'react';
//import {  useHelper } from '@react-three/drei';
import * as THREE from 'three';
import {  useThree } from '@react-three/fiber'
const Light = ({color,intensity,distance,angle, penumbra,decay, position, target}) => {
    const light = useMemo(() => {
        return new THREE.SpotLight(color,intensity,distance,angle, penumbra,decay)
    }, [
        color,intensity,distance,angle, penumbra,decay
    ]);

    return (<>
            <primitive object={light} position={position} />
            <primitive object={light.target} position={target} />
            </>
    );

};
/*
const LightDebug = ({target,...props}) => {
    //EXCLUSIVE FOR SETTING UP LIGHTS
    const light = useRef();
    useHelper(light, THREE.SpotLightHelper, props.color);
    //TODO: RENDERING AT EVERY FRAME, CHECK WHY LIGHT REFERENCE IS CHANGING UUID AT EVERY FRAME.
    useEffect (()=>{
        light.current.target.position.x = target[0];
        light.current.target.position.y = target[1];
        light.current.target.position.z = target[2];
        light.current.target.updateMatrixWorld();

    }, []);

    return (
            <spotLight
        ref={light}
        {...props}
        shadow-mapSize-width={512}
        shadow-mapSize-height={512}
        castShadow

            />
    );
};
*/
function Lights() {
    const {camera} = useThree();

    return (
            <>
            <ambientLight intensity={(camera.position.z > 11.5 ? 0.7 : 0.25)}></ambientLight>
            <Light
        color="#b580f5"
        position={[18, 24, 30]}
        angle={.3}
        distance={27}
        decay={0.}
        intensity={1.}
        penumbra={0.9}
        target={[12,6,30]}
            />
            <Light
        color="#ffffaf"
        position={[0, 24, 18]}
        angle={(camera.position.z > 11.5 ? 0.8 : 0.3)}
        distance={25}
        decay={0.}
        intensity={1.}
        penumbra={0.05}
        target={[0,6,20]}
            />

            <Light
        color="#0080f5"
        position={[-6, 24, 5]}
        angle={.2}
        distance={30}
        decay={0.2}
        intensity={1.}
        penumbra={0.9}
        target={[-7,0,-2]}
            />

        </>
    );
}

export default Lights;
