import DisplayInfo from './DisplayInfo.js';
import React from 'react';
import '../styles/ui.css';
import {isMobile} from 'react-device-detect';

let data = require("../data.json");

const Arrows = function () {
  return (
    <div className="arrows">
      <div className="arrows-row">
        <img alt="arrow-up" className="arrow" src="UI/Up.png"></img>
      </div>
      <div className="arrows-row">
        <img alt="arrow-left" className="arrow" src="UI/Left.png"></img>
        <img alt="arrow-down" className="arrow" src="UI/Down.png"></img>
        <img alt="arrow-right" className="arrow" src="UI/Right.png"></img>
      </div>
    </div>
  )
}

function UI({show,init, ...props}) {
    const {helpContainer, cubeButton, aboutContainer, socialmedia} = data;
    const {mobile, desktop } = helpContainer;

    const bio = aboutContainer.bio.map((s,i)=>{
        return (i===0 ? <b key={"bio-"+i}>{s}</b>: <p key={"bio-"+i}>{s}</p>)
    });
    const artwork = aboutContainer.artwork.map((s,i)=>{
        return (i===0 ? <b key={"bio-"+i}>{s}</b>: <p key={"bio-"+i}>{s}</p>)

    });

    const {email, instagram, web} = socialmedia;

    return (
            <main>
            <DisplayInfo id="helpContainer" src="UI/Question.png" styleClass="help" show={show}>
            <p>{isMobile? mobile[0] : desktop[0] }</p>
            <p>{isMobile? mobile[1] : desktop[1] }</p>
            {isMobile? null: <Arrows />}
        {init? <button className="enter">Enter</button> : null}
            </DisplayInfo>

            <DisplayInfo
        openText={aboutContainer.open}
        styleClass="about"
        show={false}
        {...props}
            >
            <h1 className="about-title">{aboutContainer.title}</h1>
            <div className="about-text" id="about">
            {bio}
        {" "}
        <a href="http://indexfoundation.se/exhibitions/linnea-hansander-tragedy-i-cant-live-if-living-is-without-you" className="">Read more about the exhibition TRAGEDY. I CAN’T LIVE IF LIVING IS WITHOUT YOU at Index here.</a>
        {artwork}
        </div>

            <div className="social-networks">
            {email ?
             <a href={email}>
             <img alt="mail-src" src="UI/mail.png" />
             </a>: null}
        {instagram ?
         <a href={instagram}>
         <img alt="insta-src" src="UI/insta.png" />
         </a>:null}
        {web ?
         <a href={web} className="invert">
         <img alt="web-src" src="UI/Web.png" />
         </a> : null}
        </div>
            </DisplayInfo>


            <button id="cubContainer" className="cube" type="button" onClick={() => window.open(cubeButton.webpage , 'blank')}>
            <img alt="cube-src" src="UI/Cube.png" />
            </button>
            </main>
  )
}

export default UI;
