import React,{useState, useRef ,useEffect} from 'react'
import * as ReactDOM from 'react-dom/client'
import './index.css'
import "./styles/onboarding.css";
import { useAudioManager } from './components/useAudioManager';
import {isMobile} from 'react-device-detect';
import App from './App';
import UI from './components/UI.js';


import { useTransition, a } from "react-spring";
import * as THREE from "three";

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)
/*
  if(play ){
  sound.play();
  const de = document.getElementById('debug-0');
  de.innerHTML += ". Playing";
  }

 */

const MAX = isMobile? 50 : 300;
function Welcome({onSuccess,  offTransition, enter}) {


    useEffect(()=>{
        const handler = ()=>{
            onSuccess();
            document.removeEventListener("touchstart",handler, { passive: true});
            document.removeEventListener("click",handler, { passive: true});
        }

        document.addEventListener("touchstart", handler, { passive: true});
        document.addEventListener("click", handler, { passive: true});

        return () => {
            document.removeEventListener("touchstart",handler, { passive: true});
            document.removeEventListener("click",handler, { passive: true});

        };
    },[onSuccess]);


    const [finished, set] = useState(false);
    const [totalopacity, setTotalopacity] = useState(1);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        THREE.DefaultLoadingManager.onLoad = () => {
            set(true);
        }
        THREE.DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) =>{
            // console.log("Loading scene: ", (itemsLoaded / itemsTotal)*100, "%");
        }
        if (enter) {setTotalopacity(0.75)};
        if (enter && finished){
            if(counter<MAX){ setCounter(counter+1)};
            if(counter>=MAX){ offTransition();}
        }

    }, [ finished, counter, enter, offTransition, totalopacity]);

    const transitions = useTransition(finished, {
        from: { opacity: 1, counter: 0 },
        leave: { opacity: 0 },
        update: { counter }
    });


    return (  <div style={{ display: 'flex' }}>
              {transitions(
                  (props) =>
                      <a.div className={"loading"} style={{ opacity: (1 - counter/MAX)*totalopacity }}/>)}
              </div> )
}


function AppWrapper() {
    const ref = useRef();
    let [ui, setUi] = useState(false);
    useEffect(() => {
        setUi(true)
    }, [ref])

    const [transition, offTransition] = useState(false);
    const [enter, setEnter] = useState(false);

    const didLoadAllAudio = (state) =>{
        const v = Object.values(state.audiosRef).every(Boolean)
        return v;
    };




    const allAudioLoaded =  useAudioManager(didLoadAllAudio);

    const WrappedUI= React.forwardRef((props, ref)=> <UI {...props} forwardRef={ref}/>);
    const refs = useAudioManager((state) => state.audiosRef);

    const onSuccess=()=>{
        if (allAudioLoaded){
            setEnter(true);

//            const de = document.getElementById('debug-0');
           
            Object.values(refs).map((ref, i)=>
                                    {  if(ref.source){
                                        const audioCtx=ref.source.context;
                                        console.log(ref.source.context.state, audioCtx.state);
                                        if(audioCtx.state === 'running') {
                                            audioCtx.suspend().then(function() {
                                                //de.textContent = 'Resume context';
                                            });
                                        } else if(audioCtx.state === 'suspended') {

                                            audioCtx.resume().then(function() {
                                               // de.textContent = 'Suspend context';
                                            });
                                        }
                                    }
                                       ref.play(0);
                                       return null;


                                    })


        }

    };
    return (<>
            <WrappedUI ref={ref}  init={!enter}  show={ enter ? false : ui && allAudioLoaded} wait/>
            <App loaded={enter}/>
            {!transition ?
             <Welcome enter={enter}
             onSuccess={onSuccess}
             offTransition={() => offTransition(true)}/>  : null}

            </>)
}


root.render(<AppWrapper/>)
