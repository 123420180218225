import { Environment } from '@react-three/drei'
import {  LayerMaterial, Depth, } from 'lamina'
import { useState ,Suspense, useMemo } from 'react'
import {  useThree } from '@react-three/fiber'
import * as THREE  from "three";


const mix = function(v0,v1,t){
    return Math.floor(v0*t+v1*(1.-t));
}

function Sky({onUpdate}){
    const DYNAMIC = "rgb(24, 28, 94)";
    const COLOR_A = "rgb(29, 29, 29)";
    const [dynamicBackground, setDynamicBackground] = useState(DYNAMIC);
    const [colorA, setColorA] = useState(COLOR_A);
    const [dark, setDark] = useState(0);
    const {camera} = useThree();
    const [processing, setProcessing] = useState(false);

    useMemo(()=>{
        if(processing) return;
        if(!onUpdate) return;
        if(camera){
            setProcessing(true);
            const x = camera.position ? camera.position.z : 0;
            const int =(Math.min(15.,Math.max(x,-5.)) + 5)/20;
            const r = mix(220.,0.,int);
            const g = mix(250.,0.,int);
            const b = mix(250.,75.,int);

            setDynamicBackground(`rgb(${r}, ${g}, ${b})`);

            const r1 = mix(255.,1.,int);
            const g1 = mix(255.,1.,int);
            const b1 = mix(255.,1.,int);

            setColorA(`rgb(${r1}, ${g1}, ${b1})`);
            setDark(1.-int);
            setProcessing(false);
        }
    },[onUpdate,camera, processing])


    return(
            <Suspense>
            <Environment background near={1} far={10} >
            <mesh scale={7}>
            <sphereGeometry args={[1, 64, 64]}/>
            <LayerMaterial color={(dynamicBackground? dynamicBackground : DYNAMIC)} attach="material" side={THREE.BackSide}>
            <Depth colorA={(colorA ? colorA : COLOR_A)} colorB="black"
        alpha={.97}
        mode="add"
        near={0}
        far={8}
        origin={[0, 5, 0]}
            />
            <Depth colorA="white"  colorB="black"
        alpha={(dark ? dark*1.5 : 0)}
        mode="multiply"
        near={0}
        far={8}
        origin={[0, 5, 0]}
            />
            </LayerMaterial>
            </mesh>
            </Environment>
            </Suspense>

    )

}

export default Sky;
