import CustomEffects from './components/CustomEffects.js'
import Model from './components/Model.js'
import MobileViewer from './components/MobileViewer.js'
import Audio from './components/Audio.js'
import Lights from './components/Lights.js'
import Viewer from './components/Viewer.js'
import Sky from './components/Sky.js'

import { Canvas } from '@react-three/fiber'
import { useState, Suspense } from 'react'
import {isMobile} from 'react-device-detect';
import { WebGLRenderer} from "three";
import ErrorBoundary from './components/ErrorBoundary.js'
import { useAudioManager } from './components/useAudioManager';
import {Html}  from '@react-three/drei';

import { hot } from 'react-hot-loader/root'


function App({loaded}) {
    const cubeSize = { x: 25,y: 25 * 0.64,z: 25}

    const [userInteraction, setUserinteracion] =useState(true);

    const cameraOptions = {far: 100, fov: 60, aspect: window.width/window.height, position: [-5, -1, -3.5], rotation: [ 0,Math.PI,0]};


    const didLoadAllAudio = (state) =>{
        const v = Object.values(state.audiosRef).every(Boolean)
        return v;
    };

    const allAudioLoaded =  useAudioManager(didLoadAllAudio);
    const modelPath = isMobile ? "rooms/lowres/" : "rooms/highres/";
    return (
            <ErrorBoundary>

            <Canvas dpr={[2, 1]} shadows  camera={cameraOptions}
        gl={canvas => new WebGLRenderer({ canvas,
                                          logarithmicDepthBuffer: true,
	                                      antialias:false })}>
            <Suspense fallback={<Html><h1>App loading....</h1></Html>}>
            <Model name="room1" path={modelPath} mtlFile="Room1.mtl" objFile="Room1.obj" size={cubeSize} position={[0,-0.35,0]}  />
            <Model name="room2" path={modelPath} mtlFile="Room2.mtl" objFile="Room2.obj" size={cubeSize} position={[0.,-0.35,0]} />
            {!isMobile ?
             <Viewer position={[0, 0, 9]} callback={setUserinteracion} /> :
             <MobileViewer callback={setUserinteracion} />}
            <Audio start={loaded && allAudioLoaded}/>
            <CustomEffects/>
            <Lights/>
            <Sky onUpdate={userInteraction}/>
            </Suspense>
            </Canvas>

        </ErrorBoundary>

    )
}

export default hot(App);
/*


*/
