import { useState, useEffect } from "react";

function actionByKey(key) {
  const keys = {
    ArrowUp: "moveForward",
    ArrowDown: "moveBackward",
    ArrowLeft: "moveLeft",
    ArrowRight: "moveRight",
  };
  return keys[key];
}

export const useKeyboardControls = ({onSuccess}) => {

    //Number true == 1, false == 0
  const [movement, setMovement] = useState({
    moveForward: 0,
    moveBackward: 0,
    moveLeft: 0,
    moveRight: 0,
  });

  useEffect(() => {
      const handleKeyDown = (e) => {
          
      if (actionByKey(e.code)) {
          setMovement((state) => ({ ...state, [actionByKey(e.code)]: 1 }));
          onSuccess(true)

      }
    };
    const handleKeyUp = (e) => {
      if (actionByKey(e.code)) {
          setMovement((state) => ({ ...state, [actionByKey(e.code)]: 0 }));

      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [onSuccess]);

  return movement;
};
