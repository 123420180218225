import create from 'zustand';
import {isMobile} from 'react-device-detect';

const useAudioManagerDesktop = create((set) => ({
    didLoad: {
        audio1: false,
        audio3: false,
        audio4: false,
        audio5: false,
    },
    audiosRef: {
        audio1: null,
        audio3: null,
        audio4: null,
        audio5: null
    },
    setLoaded: (type, loaded) =>
        set((state) => {
            return {
                didLoad: { ...state.didLoad, [type]: loaded },
            };
        }),
    setAudioRef: (type, ref) =>
        set((state) => {
            return {
                audiosRef: { ...state.audiosRef, [type]: ref },
            };
        })
}));

const useAudioManagerMobile = create((set) => ({
    didLoad: {
        audiofull: false,
    },
    audiosRef: {
        audiofull: null,
    },
    setLoaded: (type, loaded) =>
        set((state) => {
            return {
                didLoad: { ...state.didLoad, [type]: loaded },
            };
        }),
    setAudioRef: (type, ref) =>
        set((state) => {
            return {
                audiosRef: { ...state.audiosRef, [type]: ref },
            };
        })
}));


export const useAudioManager = isMobile ? useAudioManagerMobile : useAudioManagerDesktop;
