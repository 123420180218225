import * as THREE from "three"
import { extend } from "@react-three/fiber"

class ParticleMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      transparent: true,
      uniforms: { time: { value: 1 } },
      vertexShader: `uniform float time;
      attribute float size;
        varying vec3 ppp;

     vec3 posf2(float t, float i) {
        return vec3(
            sin(t+i*.9553) +
            sin(t*1.311+i) +
            sin(t*1.4+i*1.53) +
            sin(t*1.84+i*.76),
            sin(t+i*.79553+2.1) +
            sin(t*1.311+i*1.1311+2.1) +
            sin(t*1.4+i*1.353-2.1) +
            sin(t*1.84+i*.476-2.1),
            sin(t+i*.5553-2.1) +
            sin(t*1.311+i*1.1-2.1) +
            sin(t*1.4+i*1.23+2.1) +
            sin(t*1.84+i*.36+2.1)
        )*.2;
      }

      vec3 posf0(float t) {
        return posf2(t,-1.)*3.5;
      }

      vec3 posf(float t, float i) {
        return posf2(t*.3,i) + posf0(t);
      }

      vec3 push(float t, float i, vec3 ofs, float lerpEnd) {
        vec3 pos = posf(t,i)+ofs;

        vec3 posf = fract(pos+.5)-.5;

        float l = length(posf)*2.;
        return (- posf + posf/l)*(1.-smoothstep(lerpEnd,1.,l));
      }

      void main() {
        float PI = 3.1415926538;
        float x = position.x;
        float y = position.y;
        float id = position.z;

        float t = time*.0075;
        float i = id+sin(id)*100.;

        vec3 pos = posf(t,i);
        vec3 ofs = vec3(0);

        for (float f = -10.; f < 0.; f++) {
          ofs += push(t+f*.05,i,ofs,2.-exp(-f*.1));
        }
        ofs += push(t,i,ofs,.999);

        pos -= posf0(t);

        pos += ofs;


        pos.yz *= mat2(.8,.6,-.6,.8);
        pos.xz *= mat2(.8,.6,-.6,.8);

        pos *= 30.;
        ppp = vec3(pos.xy, id);

       pos.z = abs(pos.z);


      pos.z -=5.;
      pos.xy *= 2./pos.z;
       pos.z -=4.;

        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos.xzy, 1.0 );
        gl_PointSize = size;
      }`,
      fragmentShader: `uniform float time;
      varying vec3 ppp;
      vec3 pal( in float t)
{
    vec3 a = vec3(.75);
    vec3 b = vec3(.25);
    vec3 c = vec3(1.0, 1.0, 0.5	);
    vec3 d = vec3(0.80, 0.90, 0.30);

    return a + b*cos( 6.28318*(c*t+d) );
}


      void main() {
        gl_FragColor = vec4(pal(length(ppp)+time*.05), step(length(gl_PointCoord.xy - vec2(0.1)), 0.8));
      }`
    })
  }
}

extend({ ParticleMaterial })
